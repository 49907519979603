import { DefaultEditor } from './presets/default-editor'
import { QuestionBodyEditor } from './presets/question-body-editor'
import { NarrativeAdvancedTableEditor } from './presets/narrative-advanced-table-editor'

export default {
    DefaultEditor,

    /* Question authoring */
    QuestionBodyEditor,

    /* Narrative authoring */
    NarrativeAdvancedTableEditor
}
