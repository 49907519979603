import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

export class ToolbarInsertImagePlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('customInsertImage', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Image',
                icon: imageIcon,
                tooltip: true
            });

            view.on('execute', () => {
                window.postMessage({ type: 'insertImage', id: this.editor.id }, '*');
            });

            return view;
        });

        editor.model.schema.extend('imageBlock', { allowAttributes: 'data-placement-id' });

        editor.conversion.for('upcast').attributeToAttribute({
            view: 'data-placement-id',
            model: 'data-placement-id'
        });

        editor.conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:data-placement-id:imageBlock', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const figure = conversionApi.mapper.toViewElement(data.item);
                const img = figure.getChild(0);

                viewWriter.setAttribute('data-placement-id', data.attributeNewValue, img);
            });
        });

        editor.conversion.for('dataDowncast').add(dispatcher => {
            dispatcher.on('insert:imageBlock', (evt, data, conversionApi) => {
                const modelElement = data.item;
                const viewFigure = conversionApi.mapper.toViewElement(modelElement);
                const viewImg = viewFigure.getChild(0);
                if (viewImg) {

                    conversionApi.writer.removeAttribute('height', viewImg);
                    conversionApi.writer.removeAttribute('width', viewImg);
                    conversionApi.writer.setAttribute('style', 'max-width: 100%', viewImg);
                }
            });
        });
    }
}
