import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';

export class VideoPlugin extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        editor.model.schema.register('video', {
            allowWhere: '$block',
            allowAttributes: ['src', 'controls', 'width', 'type', 'data-placement-id', 'crossorigin'],
            isObject: true,
            isBlock: true,
        });

        editor.model.schema.register('track', {
            allowIn: 'video',
            allowAttributes: ['src', 'label', 'kind', 'srclang', 'default'],
        });

        editor.conversion.for('upcast').elementToElement({
            view: {
                name: 'video',
                attributes: ['src', 'controls', 'width', 'type', 'data-placement-id', 'crossorigin'],
            },
            model: (viewElement, { writer: modelWriter }) => {
                return modelWriter.createElement('video', {
                    src: viewElement.getAttribute('src'),
                    controls: viewElement.getAttribute('controls'),
                    width: viewElement.getAttribute('width'),
                    type: viewElement.getAttribute('type'),
                    'data-placement-id': viewElement.getAttribute('data-placement-id'),
                    crossorigin: viewElement.getAttribute('crossorigin'),
                });
            }
        });

        editor.conversion.for('dataDowncast').elementToElement({
            model: 'video',
            view: (modelElement, { writer: viewWriter }) => {
                return viewWriter.createContainerElement('video', {
                    src: modelElement.getAttribute('src'),
                    controls: modelElement.getAttribute('controls'),
                    width: modelElement.getAttribute('width'),
                    type: modelElement.getAttribute('type'),
                    'data-placement-id': modelElement.getAttribute('data-placement-id'),
                    crossorigin: modelElement.getAttribute('crossorigin'),
                });
            }
        });

        editor.conversion.for('editingDowncast').elementToElement({
            model: 'video',
            view: (modelElement, { writer: viewWriter }) => {
                // Create a wrapper div for the video element.
                const wrapper = viewWriter.createContainerElement('div', { class: 'video-wrapper' });
                const videoElement = viewWriter.createContainerElement('video', {
                    src: modelElement.getAttribute('src'),
                    controls: modelElement.getAttribute('controls'),
                    width: modelElement.getAttribute('width'),
                    type: modelElement.getAttribute('type'),
                    'data-placement-id': modelElement.getAttribute('data-placement-id'),
                    crossorigin: modelElement.getAttribute('crossorigin'),
                });

                for (const child of modelElement.getChildren()) {
                    const trackElement = viewWriter.createEmptyElement('track', {
                        src: child.getAttribute('src'),
                        label: child.getAttribute('label'),
                        kind: child.getAttribute('kind'),
                        srclang: child.getAttribute('srclang'),
                        default: child.getAttribute('default'),
                    });
                    viewWriter.insert(viewWriter.createPositionAt(videoElement, 'end'), trackElement);
                }

                viewWriter.insert(viewWriter.createPositionAt(wrapper, 0), videoElement);
                const widget = toWidget(wrapper, viewWriter, { label: 'video widget' });

                return widget;
            }
        });

        editor.conversion.for('upcast').attributeToAttribute({
            view: 'width',
            model: 'width'
        });

        editor.conversion.for('upcast').elementToElement({
            view: {
                name: 'track',
                attributes: ['src', 'label', 'kind', 'srclang', 'default'],
            },
            model: (viewElement, { writer: modelWriter }) => {
                return modelWriter.createElement('track', {
                    src: viewElement.getAttribute('src'),
                    label: viewElement.getAttribute('label'),
                    kind: viewElement.getAttribute('kind'),
                    srclang: viewElement.getAttribute('srclang'),
                    default: viewElement.getAttribute('default'),
                });
            }
        });

        editor.conversion.for('dataDowncast').elementToElement({
            model: 'track',
            view: (modelElement, { writer: viewWriter }) => {
                return viewWriter.createEmptyElement('track', {
                    src: modelElement.getAttribute('src'),
                    label: modelElement.getAttribute('label'),
                    kind: modelElement.getAttribute('kind'),
                    srclang: modelElement.getAttribute('srclang'),
                    default: modelElement.getAttribute('default'),
                });
            }
        });

        editor.conversion.for('editingDowncast').elementToElement({
            model: 'track',
            view: (modelElement, { writer: viewWriter }) => {
                return viewWriter.createEmptyElement('track', {
                    src: modelElement.getAttribute('src'),
                    label: modelElement.getAttribute('label'),
                    kind: modelElement.getAttribute('kind'),
                    srclang: modelElement.getAttribute('srclang'),
                    default: modelElement.getAttribute('default'),
                });
            }
        });
    }
}
