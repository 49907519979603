import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'

import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import type { EditorConfig } from '@ckeditor/ckeditor5-core'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Link } from '@ckeditor/ckeditor5-link'
import { List, ListProperties } from '@ckeditor/ckeditor5-list'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { Table, TableCaption, TableCellProperties, TableCellPropertiesEditing, TableColumnResize, TableProperties, TableToolbar } from '@ckeditor/ckeditor5-table'
import { DataFilter, DataSchema, GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support'
import { CodeBlock } from '@ckeditor/ckeditor5-code-block'
import { Font } from '@ckeditor/ckeditor5-font'
import { Style } from '@ckeditor/ckeditor5-style'

export class NarrativeAdvancedTableEditor extends ClassicEditor {
    public static override builtinPlugins = [
        Alignment,
        BlockQuote,
        Bold,
        Code,
        CodeBlock,
        DataFilter,
        DataSchema,
        Essentials,
        Font,
        GeneralHtmlSupport,
        Heading,
        Indent,
        Italic,
        Link,
        List,
        ListProperties,
        Paragraph,
        PasteFromOffice,
        Strikethrough,
        Style,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableCellPropertiesEditing,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        Underline,
    ]

    public static override defaultConfig: EditorConfig = {
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                '|',
                'alignment',
                'bulletedList',
                'numberedList',
                'outdent',
                'indent',
                '|',
                'insertTable',
                'style',
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties'
            ],
            shouldNotGroupWhenFull: true
        },
        language: 'en',
        table: {
            contentToolbar: []
        }
    }

    constructor(sourceElementOrData: string | HTMLElement, config?: EditorConfig | undefined) {
        super(sourceElementOrData, config)

        setTimeout(() => {
            this.disableTableCellPropertiesOptions()
            this.addCustomCss()
        }, 500) // needed so plugins are initialized
    }

    disableTableCellPropertiesOptions() {
        // Map the disabled options to their corresponding command IDs.
        const optionToCommandMap = {
            TableCellWidth: 'tableCellWidth',
            TableCellBorderStyle: 'tableCellBorder',
            TableCellBorderColor: 'tableCellBorderColor',
            TableCellBorderWidth: 'tableCellBorderWidth',
            TableCellHeight: 'tableCellHeight',
            TableCellPadding: 'tableCellPadding',
            TableCellBackgroundColor: 'tableCellBackgroundColor',
        }

        // Make sure the TableCellProperties plugin is recognized.
        const cellPropertiesPlugin = this.plugins.get(TableCellProperties)
        if (!cellPropertiesPlugin) {
            console.error('TableCellProperties plugin not found!')
            return
        }

        Object.entries(optionToCommandMap).forEach(([key, value]) => {
            const commandID = value
            const command = cellPropertiesPlugin.editor.commands.get(commandID)
            if (command) {
                command.forceDisabled(key)
            }
            // To reenable the feature options run e.g. { editor.commands.get( 'tableCellWidth' ).clearForceDisabled( 'TableCellWidth' ) }
        })
    }

    addCustomCss() {
        const customCss = `
            /* Hide specific elements in the TableCellProperties form */
            .ck-table-cell-properties-form > .ck.ck-form__row.ck-table-form__border-row,
            .ck-table-cell-properties-form > .ck.ck-form__row.ck-table-form__background-row,
            .ck-table-cell-properties-form > .ck.ck-form__row > .ck.ck-form__row.ck-table-form__dimensions-row,
            .ck-table-cell-properties-form > .ck.ck-form__row > .ck.ck-form__row.ck-table-cell-properties-form__padding-row,
            .ck-table-cell-properties-form > .ck.ck-form__row.ck-table-form__dimensions-row,
            .ck-table-cell-properties-form > .ck.ck-form__row.ck-table-form__alignment-row {
                display: none;
            }

			/* Make the toolbar items more compact */
			.ck.ck-toolbar .ck-toolbar__items {
				display: flex;
				flex-wrap: wrap;
				gap: 9px; /* Adjust the gap between toolbar items */
			}
        `

        const customStylesheet = document.createElement('style')
        customStylesheet.textContent = customCss

        // Get the CKEditor UI view and append the custom stylesheet to it.
        const editorUi = this.ui.view
        if (editorUi.element) {
            editorUi.element.insertBefore(customStylesheet, editorUi.element.firstChild)
        }
    }
}
