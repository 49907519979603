import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import videoIcon from '@ckeditor/ckeditor5-media-embed/theme/icons/media.svg';

export class ToolbarInsertVideoPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('customInsertVideo', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Video',
                icon: videoIcon,
                tooltip: true
            });

            view.on('execute', () => {
                window.postMessage({ type: 'insertVideo', id: this.editor.id }, '*');
            });

            return view;
        });
    }
}
