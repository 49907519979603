import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export class ToolbarInsertAudioPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('customInsertAudio', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Audio',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-music-note-beamed" viewBox="0 0 16 16">
                <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13s1.12-2 2.5-2 2.5.896 2.5 2m9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2"/>
                <path fill-rule="evenodd" d="M14 11V2h1v9zM6 3v10H5V3z"/>
                <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4z"/>
              </svg>`,
                tooltip: true
            });

            view.on('execute', () => {
                window.postMessage({ type: 'insertAudio', id: this.editor.id }, '*');
            });

            return view;
        });
    }
}
