import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';

export class AudioPlugin extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        editor.model.schema.register('audio', {
            allowWhere: '$block',
            allowAttributes: ['src', 'controls', 'type', 'width', 'data-placement-id', 'crossorigin'],
            isObject: true,
            isBlock: true,
        });

        editor.conversion.for('upcast').elementToElement({
            view: {
                name: 'audio',
                attributes: ['src', 'controls', 'type', 'width', 'data-placement-id', 'crossorigin'],
            },
            model: (viewElement, { writer: modelWriter }) => {
                return modelWriter.createElement('audio', {
                    src: viewElement.getAttribute('src'),
                    controls: viewElement.getAttribute('controls'),
                    width: viewElement.getAttribute('width'),
                    type: viewElement.getAttribute('type'),
                    'data-placement-id': viewElement.getAttribute('data-placement-id'),
                    crossorigin: viewElement.getAttribute('crossorigin'),
                });
            }
        });

        editor.conversion.for('dataDowncast').elementToElement({
            model: 'audio',
            view: (modelElement, { writer: viewWriter }) => {
                return viewWriter.createContainerElement('audio', {
                    src: modelElement.getAttribute('src'),
                    controls: modelElement.getAttribute('controls'),
                    width: modelElement.getAttribute('width'),
                    type: modelElement.getAttribute('type'),
                    'data-placement-id': modelElement.getAttribute('data-placement-id'),
                    crossorigin: modelElement.getAttribute('crossorigin'),
                });
            }
        });

        editor.conversion.for('editingDowncast').elementToElement({
            model: 'audio',
            view: (modelElement, { writer: viewWriter }) => {
                const wrapper = viewWriter.createContainerElement('div', { class: 'audio-wrapper' });
                const audioElement = viewWriter.createContainerElement('audio', {
                    src: modelElement.getAttribute('src'),
                    controls: modelElement.getAttribute('controls'),
                    width: modelElement.getAttribute('width'),
                    type: modelElement.getAttribute('type'),
                    'data-placement-id': modelElement.getAttribute('data-placement-id'),
                    crossorigin: modelElement.getAttribute('crossorigin'),
                });

                viewWriter.insert(viewWriter.createPositionAt(wrapper, 0), audioElement);
                const widget = toWidget(wrapper, viewWriter, { label: 'audio widget' });

                return widget;
            }
        });

        editor.conversion.for('upcast').attributeToAttribute({
            view: 'width',
            model: 'width'
        });
    }
}
